import { Array, AsyncData, Dict, Future, Option, Result } from "@swan-io/boxed";
import { useQuery } from "@swan-io/graphql-client";
import { Box } from "@swan-io/lake/src/components/Box";
import { Cell, CopyableTextCell, HeaderCell, TextCell } from "@swan-io/lake/src/components/Cells";
import { EmptyView } from "@swan-io/lake/src/components/EmptyView";
import { Fill } from "@swan-io/lake/src/components/Fill";
import { FilterChooser } from "@swan-io/lake/src/components/FilterChooser";
import { LakeAlert } from "@swan-io/lake/src/components/LakeAlert";
import { LakeButton } from "@swan-io/lake/src/components/LakeButton";
import { Link } from "@swan-io/lake/src/components/Link";
import { Space } from "@swan-io/lake/src/components/Space";
import { Tag } from "@swan-io/lake/src/components/Tag";
import {
  ColumnConfig,
  LinkConfig,
  VirtualizedList,
  VirtualizedListPlaceholder,
} from "@swan-io/lake/src/components/VirtualizedList";
import { negativeSpacings } from "@swan-io/lake/src/constants/design";
import { deriveUnion } from "@swan-io/lake/src/utils/function";
import { isNotNullish } from "@swan-io/lake/src/utils/nullish";
import {
  FilterCheckboxDef,
  FiltersStack,
  FiltersState,
} from "@swan-io/shared-business/src/components/Filters";
import dayjs from "dayjs";
import { useEffect, useMemo, useState } from "react";
import { match, P } from "ts-pattern";
import {
  MerchantProfileFragment,
  MerchantProfileStatus,
  MerchantProfilesWithCountDocument,
} from "../graphql/partner";
import { useProjectInfo } from "../hooks/useProjectInfo";
import { locale, t } from "../utils/i18n";
import { useFiltersTracking } from "../utils/matomo";
import { RouteParams, Router } from "../utils/routes";
import { ColumnChooser, ColumnChooserConfig, useColumnChooser } from "./ColumnChooser";
import { Connection } from "./Connection";
import { ErrorView } from "./ErrorView";
import { TrackPressable } from "./TrackPressable";

type Props = {
  params: RouteParams<"MerchantList">;
};

const PER_PAGE = 20;

type ExtraInfo = undefined;

const defaultFixedColumns: ColumnConfig<MerchantProfileFragment, ExtraInfo>[] = [
  {
    id: "merchantName",
    width: 250,
    title: t("merchantProfile.list.merchantName"),
    renderTitle: ({ title }) => <HeaderCell text={title} />,
    renderCell: ({ item }) => <TextCell text={item.merchantName} />,
  },
];

const defaultActiveColumns: ColumnConfig<MerchantProfileFragment, ExtraInfo>[] = [
  {
    id: "merchantId",
    width: 400,
    title: t("merchantProfile.list.merchantId"),
    renderTitle: ({ title }) => <HeaderCell text={title} />,
    renderCell: ({ item }) => (
      <CopyableTextCell
        text={item.id}
        copyWording={t("copyButton.copyTooltip")}
        copiedWording={t("copyButton.copiedTooltip")}
      />
    ),
  },
  {
    id: "accountId",
    width: 400,
    title: t("merchantProfile.list.accountId"),
    renderTitle: ({ title }) => <HeaderCell text={title} />,
    renderCell: ({ item }) => (
      <CopyableTextCell
        text={item.accountId}
        copyWording={t("copyButton.copyTooltip")}
        copiedWording={t("copyButton.copiedTooltip")}
      />
    ),
  },
  {
    id: "updatedAt",
    width: 200,
    title: t("merchantProfile.list.updatedAt"),
    renderTitle: ({ title }) => <HeaderCell text={title} />,
    renderCell: ({ item }) => (
      <TextCell text={dayjs(item.updatedAt).format(`${locale.dateFormat} ${locale.timeFormat}`)} />
    ),
  },
  {
    id: "status",
    width: 200,
    title: t("merchantProfile.list.status"),
    renderTitle: ({ title }) => <HeaderCell text={title} />,
    renderCell: ({ item }) => (
      <Cell>
        {match(item.statusInfo.status)
          .with("Enabled", () => <Tag color="positive">{t("merchantProfile.status.enabled")}</Tag>)
          .with("PendingReview", () => (
            <Tag color="shakespear">{t("merchantProfile.status.pendingReview")}</Tag>
          ))
          .with("Rejected", () => (
            <Tag color="negative">{t("merchantProfile.status.rejected")}</Tag>
          ))
          .with("Suspended", () => (
            <Tag color="warning">{t("merchantProfile.status.suspended")}</Tag>
          ))
          .with("Disabled", () => <Tag color="gray">{t("merchantProfile.status.disabled")}</Tag>)
          .otherwise(() => null)}
      </Cell>
    ),
  },
];

const ALL_STATUSES = deriveUnion<MerchantProfileStatus>({
  Disabled: true,
  Enabled: true,
  PendingReview: true,
  Rejected: true,
  Suspended: true,
}).array;

export const MerchantList = ({ params }: Props) => {
  const { projectId, projectEnv } = useProjectInfo();

  const filters: MerchantProfileFilters = useMemo(() => {
    return {
      status: isNotNullish(params.status)
        ? Array.filterMap(params.status, item =>
            match(item)
              .with(
                P.union("Enabled", "PendingReview", "Rejected", "Suspended", "Disabled"),
                value => Option.Some(value),
              )
              .otherwise(() => Option.None()),
          )
        : undefined,
    } as const;
  }, [params.status]);

  const [data, { isLoading, setVariables, reload }] = useQuery(MerchantProfilesWithCountDocument, {
    first: PER_PAGE,
    filters: { status: filters.status ?? ALL_STATUSES },
  });

  const columns = useColumnChooser("Account>Merchants", {
    defaultFixedColumns,
    defaultActiveColumns,
  });

  const merchantProfiles = data.mapOkToResult(data =>
    Option.fromNullable(data.merchantProfiles).toResult(undefined),
  );

  const totalCount = data
    .toOption()
    .flatMap(result => result.toOption())
    .map(({ merchantProfiles }) => merchantProfiles.totalCount);

  useFiltersTracking({
    filters: filters ?? [], // TODO: use the filters object when status will be optional in it
    totalCount: totalCount.getOr(0),
    loaded: data.isDone(),
  });

  return (
    <>
      <LakeAlert variant="info" title={t("merchantProfile.list.alert")} />
      <Space height={20} />

      <FiltersForm
        filters={filters}
        columns={columns}
        totalCount={totalCount}
        onRefresh={() => reload()}
        onChangeFilters={filters => {
          Router.replace("MerchantList", {
            projectEnv,
            projectId,
            status: filters.status,
          });
        }}
      />

      <Space height={8} />

      {match(merchantProfiles)
        .with(AsyncData.P.NotAsked, AsyncData.P.Loading, () => (
          <>
            <Space height={16} />

            <VirtualizedListPlaceholder
              headerHeight={48}
              rowHeight={48}
              count={20}
              marginHorizontal={negativeSpacings[24]}
            />
          </>
        ))
        .with(AsyncData.P.Done(Result.P.Error(P.select())), error => <ErrorView error={error} />)
        .with(AsyncData.P.Done(Result.P.Ok(P.select())), data => (
          <Connection connection={data}>
            {merchantProfiles => (
              <VirtualizedList
                variant="default"
                marginHorizontal={negativeSpacings[24]}
                data={merchantProfiles?.edges.map(({ node }) => node) ?? []}
                keyExtractor={item => item.id}
                extraInfo={undefined}
                columns={columns.active}
                stickedToStartColumns={columns.fixed}
                headerHeight={48}
                rowHeight={48}
                onEndReached={() => {
                  if (merchantProfiles?.pageInfo.hasNextPage === true) {
                    setVariables({ after: merchantProfiles.pageInfo.endCursor });
                  }
                }}
                loading={{ isLoading, count: PER_PAGE }}
                renderEmptyList={() => (
                  <EmptyView
                    icon="lake-merchant"
                    borderedIcon={true}
                    title={t("merchantProfile.list.noResults")}
                  />
                )}
                getRowLink={({ item }: LinkConfig<MerchantProfileFragment, ExtraInfo>) => (
                  <Link
                    to={Router.AccountDetailMerchantProfile({
                      accountId: item.accountId,
                      projectEnv,
                      projectId,
                      merchantProfileId: item.id,
                    })}
                  />
                )}
              />
            )}
          </Connection>
        ))
        .exhaustive()}
    </>
  );
};

const typeFilter: FilterCheckboxDef<MerchantProfileStatus> = {
  type: "checkbox",
  label: t("merchantProfile.list.status"),
  checkAllLabel: t("common.filters.all"),
  items: [
    { value: "Enabled", label: t("merchantProfile.status.enabled") },
    { value: "Rejected", label: t("merchantProfile.status.rejected") },
    { value: "Suspended", label: t("merchantProfile.status.suspended") },
    { value: "Disabled", label: t("merchantProfile.status.disabled") },
    { value: "PendingReview", label: t("merchantProfile.status.pendingReview") },
  ],
};

const filtersDefinition = {
  status: typeFilter,
};

type MerchantProfileFilters = FiltersState<typeof filtersDefinition>;

type FiltersFormProps = {
  filters: MerchantProfileFilters;
  columns: ColumnChooserConfig<MerchantProfileFragment, undefined>;
  totalCount: Option<number>;
  onChangeFilters: (filters: MerchantProfileFilters) => void;
  onRefresh: () => Future<unknown>;
};

const FiltersForm = ({ filters, columns, onChangeFilters, onRefresh }: FiltersFormProps) => {
  const availableFilters: { name: keyof MerchantProfileFilters; label: string }[] = useMemo(
    () => [
      {
        name: "status",
        label: t("merchantProfile.list.status"),
      },
    ],
    [],
  );

  const [openFilters, setOpenFilters] = useState(() =>
    Dict.entries(filters)
      .filter(([, value]) => isNotNullish(value))
      .map(([name]) => name),
  );

  useEffect(() => {
    setOpenFilters(openFilters => {
      const currentlyOpenFilters = new Set(openFilters);
      const openFiltersNotYetInState = Dict.entries(filters)
        .filter(([name, value]) => isNotNullish(value) && !currentlyOpenFilters.has(name))
        .map(([name]) => name);
      return [...openFilters, ...openFiltersNotYetInState];
    });
  }, [filters]);

  const [isRefreshing, setIsRefreshing] = useState(false);

  return (
    <>
      <Box direction="row" alignItems="center">
        <FilterChooser
          filters={filters}
          openFilters={openFilters}
          label={t("common.filters")}
          onAddFilter={filter => setOpenFilters(openFilters => [...openFilters, filter])}
          availableFilters={availableFilters}
        />

        <Space width={8} />
        <ColumnChooser {...columns} />
        <Space width={8} />

        <TrackPressable action="Refresh merchant profiles list">
          <LakeButton
            ariaLabel={t("common.refresh")}
            mode="secondary"
            size="small"
            icon="arrow-counterclockwise-filled"
            loading={isRefreshing}
            onPress={() => {
              setIsRefreshing(true);
              onRefresh().tap(() => setIsRefreshing(false));
            }}
          />
        </TrackPressable>

        <Fill minWidth={16} />
      </Box>

      <Space height={12} />

      <FiltersStack
        definition={filtersDefinition}
        filters={filters}
        openedFilters={openFilters}
        onChangeFilters={onChangeFilters}
        onChangeOpened={setOpenFilters}
      />
    </>
  );
};
