import { AsyncData, Option, Result } from "@swan-io/boxed";
import { useQuery } from "@swan-io/graphql-client";
import { useCrumb } from "@swan-io/lake/src/components/Breadcrumbs";
import { LoadingView } from "@swan-io/lake/src/components/LoadingView";
import { ResponsiveContainer } from "@swan-io/lake/src/components/ResponsiveContainer";
import { commonStyles } from "@swan-io/lake/src/constants/commonStyles";
import { breakpoints } from "@swan-io/lake/src/constants/design";
import { LakeModal } from "@swan-io/shared-business/src/components/LakeModal";
import { useMemo } from "react";
import { StyleSheet } from "react-native";
import { P, match } from "ts-pattern";
import { MerchantProfileDocument } from "../graphql/partner";
import { useProjectInfo } from "../hooks/useProjectInfo";
import { NotFoundPage } from "../pages/NotFoundPage";
import { t } from "../utils/i18n";
import { Router } from "../utils/routes";
import { AccountDetailMerchantProfile } from "./AccountDetailMerchantProfile";
import { AccountDetailMerchantProfileRequestWizard } from "./AccountDetailMerchantProfileRequestWizard";
import { ErrorView } from "./ErrorView";

const styles = StyleSheet.create({
  root: {
    ...commonStyles.fill,
  },
});

type Props = {
  merchantProfileId: string;
  accountId: string;
};

export const AccountDetailMerchantProfileArea = ({ accountId, merchantProfileId }: Props) => {
  const route = Router.useRoute(["AccountDetailMerchantProfile"]);

  const [merchantProfile, { refresh }] = useQuery(MerchantProfileDocument, { merchantProfileId });
  const { projectId, projectEnv } = useProjectInfo();

  useCrumb(
    useMemo(() => {
      return merchantProfile
        .toOption()
        .flatMap(result => result.toOption())
        .flatMap(merchantProfile => Option.fromNullable(merchantProfile.merchantProfile))
        .map(merchantProfile => ({
          label: merchantProfile.merchantName,
          link: Router.AccountDetailMerchantProfile({
            merchantProfileId,
            projectId,
            projectEnv,
            accountId,
          }),
        }))
        .toUndefined();
    }, [merchantProfile, merchantProfileId, projectEnv, projectId, accountId]),
  );

  return (
    <ResponsiveContainer breakpoint={breakpoints.medium} style={styles.root}>
      {({ large }) =>
        match(merchantProfile)
          .with(AsyncData.P.NotAsked, AsyncData.P.Loading, () => <LoadingView />)
          .with(AsyncData.P.Done(Result.P.Error(P.select())), error => <ErrorView error={error} />)
          .with(AsyncData.P.Done(Result.P.Ok({ merchantProfile: P.nullish })), () => (
            <NotFoundPage />
          ))
          .with(
            AsyncData.P.Done(Result.P.Ok({ merchantProfile: P.select(P.nonNullable) })),
            merchantProfile => (
              <>
                {match(route)
                  .with({ name: "AccountDetailMerchantProfile" }, ({ params }) => (
                    <>
                      <AccountDetailMerchantProfile
                        accountId={accountId}
                        merchantProfile={merchantProfile}
                        large={large}
                        onUpdate={() => {
                          refresh();
                        }}
                      />

                      <LakeModal
                        visible={params.new === "true"}
                        title={t("merchantProfile.request.title")}
                        icon="add-circle-regular"
                        maxWidth={850}
                      >
                        <AccountDetailMerchantProfileRequestWizard
                          accountId={accountId}
                          onPressClose={() =>
                            Router.push("AccountDetailMerchantProfile", {
                              accountId,
                              projectEnv: params.projectEnv,
                              projectId: params.projectId,
                              merchantProfileId: merchantProfile.id,
                            })
                          }
                        />
                      </LakeModal>
                    </>
                  ))
                  .with(P.nullish, () => <NotFoundPage />)
                  .exhaustive()}
              </>
            ),
          )
          .exhaustive()
      }
    </ResponsiveContainer>
  );
};
