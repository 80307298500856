import { Option } from "@swan-io/boxed";
import { Box } from "@swan-io/lake/src/components/Box";
import { Fill } from "@swan-io/lake/src/components/Fill";
import { LakeHeading } from "@swan-io/lake/src/components/LakeHeading";
import { LakeRadio } from "@swan-io/lake/src/components/LakeRadio";
import { LakeText } from "@swan-io/lake/src/components/LakeText";
import { InformationTooltip } from "@swan-io/lake/src/components/LakeTooltip";
import { Space } from "@swan-io/lake/src/components/Space";
import { Tick } from "@swan-io/lake/src/components/Tick";
import {
  backgroundColor,
  colors,
  invariantColors,
  negativeSpacings,
  radii,
  spacings,
} from "@swan-io/lake/src/constants/design";
import { isNotNullish, isNotNullishOrEmpty } from "@swan-io/lake/src/utils/nullish";
import { ColorValue, Pressable, StyleSheet, View } from "react-native";

const styles = StyleSheet.create({
  root: {
    alignSelf: "stretch",
    alignItems: "stretch",
    flexGrow: 1,
    overflow: "hidden",
    marginHorizontal: negativeSpacings[12],
    paddingTop: spacings[4],
    paddingBottom: spacings[24],
    flexDirection: "row",
  },
  item: {
    flexGrow: 1,
    flexBasis: "0%",
    paddingHorizontal: spacings[12],
  },
  contents: {
    alignItems: "center",
    alignSelf: "stretch",
    flexGrow: 1,
  },
  line: {
    width: "100%",
    verticalAlign: "middle",
  },
  tile: {
    height: 84,
    flexGrow: 1,
    paddingVertical: 16,
    paddingHorizontal: 24,
    borderColor: colors.gray[100],
    borderWidth: 1,
    borderRadius: radii[8],
  },
  selected: {
    borderColor: colors.current[500],
    backgroundColor: backgroundColor.accented,
  },
});

type Filter = {
  name: string;
  value: string;
  help?: string;
  count: Option<number>;
  color: ColorValue;
};

type FilterTilesProps = {
  filters: Filter[];
  selectedFilter?: string;
  onFilterPressed: (value: string) => void;
};

export const FilterTiles = ({ filters, selectedFilter, onFilterPressed }: FilterTilesProps) => (
  <View>
    <View style={styles.root}>
      {filters.map((filter, i) => {
        const isSelected = isNotNullishOrEmpty(selectedFilter) && filter.value === selectedFilter;
        return (
          <Pressable
            onPress={() => onFilterPressed(filter.value)}
            key={`filter-tile-${filter.value}-${i}`}
            style={styles.item}
          >
            <View style={[styles.tile, isSelected && styles.selected]}>
              <View style={styles.contents}>
                <Box direction="row" style={styles.line} alignItems="center">
                  <Tick color={filter.color} />
                  <Space width={12} />

                  <LakeText variant="smallMedium" color={invariantColors.black}>
                    {filter.name}
                  </LakeText>

                  <Fill />

                  {isNotNullish(filter.help) ? (
                    <InformationTooltip
                      icon="question-circle-regular"
                      text={filter.help}
                      iconSize={16}
                    />
                  ) : null}

                  <Space width={12} />
                  <LakeRadio value={isSelected} />
                </Box>

                <Space height={8} />

                <Box direction="row" style={styles.line} alignItems="center">
                  {filter.count.isSome() && (
                    <LakeHeading level="none" variant="h3" color={colors.current[500]}>
                      {filter.count.get()}
                    </LakeHeading>
                  )}
                </Box>
              </View>
            </View>
          </Pressable>
        );
      })}
    </View>
  </View>
);
