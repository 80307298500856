import { useQuery } from "@swan-io/graphql-client";
import { ScrollView } from "@swan-io/lake/src/components/ScrollView";
import { Tag } from "@swan-io/lake/src/components/Tag";
import { commonStyles } from "@swan-io/lake/src/constants/commonStyles";
import { backgroundColor, colors, spacings } from "@swan-io/lake/src/constants/design";
import { StyleSheet, View } from "react-native";
import { match } from "ts-pattern";
import { AccountHoldersArea } from "../components/AccountHoldersArea";
import { AccountMembershipsArea } from "../components/AccountMembershipsArea";
import { AccountsArea } from "../components/AccountsArea";
import { CardArea } from "../components/CardArea";
import { OnboardingArea } from "../components/OnboardingArea";
import { Menu, Sidebar } from "../components/Sidebar";
import {
  GetAccountHoldersVerificationCountersDocument,
  GetTransactionsVerificationCounterDocument,
} from "../graphql/partner";
import { usePermissions } from "../hooks/usePermissions";
import { useProjectInfo } from "../hooks/useProjectInfo";
import { AccessDeniedPage } from "../pages/AccessDeniedPage";
import { NotFoundPage } from "../pages/NotFoundPage";
import { t } from "../utils/i18n";
import { Router, dataRoutes } from "../utils/routes";
import { useTgglFlag } from "../utils/tggl";
import { CapitalDepositArea } from "./CapitalDepositArea";
import { MerchantArea } from "./MerchantArea";
import { TransactionsArea } from "./TransactionsArea";
import { UserArea } from "./UserArea";

const styles = StyleSheet.create({
  base: {
    flexGrow: 1,
    flexShrink: 1,
    alignItems: "stretch",
  },
  contentContainer: {
    marginHorizontal: "auto",
    borderRightWidth: 1,
    borderColor: colors.gray[50],
    backgroundColor: backgroundColor.default,
  },
  container: {
    flexGrow: 1,
    flexShrink: 1,
    maxWidth: 2560,
    marginHorizontal: "auto",
    flexDirection: "row",
  },
  content: {
    flexGrow: 1,
    paddingHorizontal: spacings[24],
    paddingTop: spacings[16],
  },
  submenuTag: {
    height: 24,
  },
});

type Props = {
  membershipEmail: string | undefined;
};

export const DataArea = ({ membershipEmail }: Props) => {
  const { projectId, projectEnv } = useProjectInfo();
  const route = Router.useRoute(dataRoutes);

  const shouldDisplayTransactionVerification = useTgglFlag("transactionVerification").getOr(false);

  const {
    dataUser: { read: canViewUser },
    dataOnboarding: { read: canViewOnboarding },
    dataAccountHolder: { read: canViewAccountHolder },
    dataAccount: { read: canViewAccount },
    dataAccountMembership: { read: canViewAccountMembership },
    dataCard: { read: canViewCard },
    dataCapitalDeposit: { read: canViewCapitalDeposit },
    dataTransaction: { read: canViewTransaction },
  } = usePermissions(projectEnv);

  const [data] = useQuery(GetAccountHoldersVerificationCountersDocument, {});

  const accountHolderVerificationTotalCount = data
    .toOption()
    .flatMap(result => result.toOption())
    .map(
      ({ pending, notStarted, waitingForInformation }) =>
        pending.totalCount + notStarted.totalCount + waitingForInformation.totalCount,
    );

  const [dataTransaction] = useQuery(GetTransactionsVerificationCounterDocument, {});

  const transactionsVerificationTotalCount = dataTransaction
    .toOption()
    .flatMap(result => result.toOption())
    .map(({ supportingDocumentCollections }) => supportingDocumentCollections.totalCount);

  const menu: Menu = [
    {
      matchRoutes: ["UserArea"],
      to: Router.UserList({ projectId, projectEnv }),
      icon: "person-regular",
      iconActive: "person-filled",
      name: t("menu.userList"),
    },
    {
      matchRoutes: ["OnboardingArea"],
      to: Router.OnboardingRoot({ projectId, projectEnv }),
      icon: "flag-regular",
      iconActive: "flag-filled",
      name: t("menu.onboardingList"),
    },
    {
      matchRoutes: ["HoldersArea"],
      to: Router.HoldersRoot({ projectId, projectEnv }),
      icon: "person-accounts-regular",
      iconActive: "person-accounts-filled",
      name: t("menu.accountHolders"),
      submenu: [
        {
          matchRoutes: ["HoldersVerificationArea"],
          to: Router.HoldersVerificationArea({ projectId, projectEnv }),
          icon: "checkmark-starburst-regular",
          iconActive: "checkmark-starburst-filled",
          name: t("menu.verifications"),
          children: (
            <Tag
              style={[
                styles.submenuTag,
                !accountHolderVerificationTotalCount.isSome() && commonStyles.hidden,
              ]}
            >
              {accountHolderVerificationTotalCount.getOr(0)}
            </Tag>
          ),
        },
      ],
    },
    {
      matchRoutes: ["AccountsArea"],
      to: Router.AccountsRoot({ projectId, projectEnv }),
      icon: "building-bank-regular",
      iconActive: "building-bank-filled",
      name: t("menu.accounts"),
    },
    {
      matchRoutes: ["MerchantArea"],
      to: Router.MerchantList({ projectId, projectEnv }),
      icon: "building-shop-regular",
      iconActive: "building-shop-filled",
      name: t("menu.merchants"),
    },
    {
      matchRoutes: ["AccountMembershipsArea"],
      to: Router.AccountMembershipsRoot({ projectId, projectEnv }),
      icon: "people-community-regular",
      iconActive: "people-community-filled",
      name: t("menu.accountMemberships"),
    },
    {
      matchRoutes: ["CardArea"],
      to: Router.CardRoot({ projectId, projectEnv }),
      icon: "payment-regular",
      iconActive: "payment-filled",
      name: t("menu.cards"),
    },
    {
      matchRoutes: ["CapitalDepositArea"],
      to: Router.CapitalDepositList({ projectId, projectEnv }),
      icon: "receipt-money-regular",
      iconActive: "receipt-money-filled",
      name: t("menu.capitalDeposits"),
    },
    {
      matchRoutes: ["TransactionsArea"],
      to: Router.TransactionsList({ projectId, projectEnv }),
      icon: "arrow-swap-filled",
      iconActive: "arrow-swap-filled",
      name: t("menu.transactions"),
      ...(shouldDisplayTransactionVerification && {
        submenu: [
          {
            matchRoutes: ["TransactionsVerificationArea"],
            to: Router.TransactionsVerificationArea({ projectId, projectEnv }),
            icon: "checkmark-starburst-regular",
            iconActive: "checkmark-starburst-filled",
            name: t("menu.verifications"),
            children: (
              <Tag
                style={[
                  styles.submenuTag,
                  !transactionsVerificationTotalCount.isSome() && commonStyles.hidden,
                ]}
              >
                {transactionsVerificationTotalCount.getOr(0)}
              </Tag>
            ),
          },
        ],
      }),
    },
  ];

  if (
    [
      canViewUser,
      canViewOnboarding,
      canViewAccountHolder,
      canViewAccount,
      canViewAccountMembership,
      canViewCard,
      canViewCapitalDeposit,
      canViewTransaction,
    ].every(item => item === false)
  ) {
    return <AccessDeniedPage />;
  }

  return (
    <ScrollView horizontal={true} style={commonStyles.fill} contentContainerStyle={styles.base}>
      <View style={styles.container}>
        <Sidebar menu={menu} />

        <ScrollView
          role="main"
          style={styles.contentContainer}
          contentContainerStyle={styles.content}
        >
          {match(route)
            .with({ name: "UserArea" }, () =>
              canViewUser ? <UserArea membershipEmail={membershipEmail} /> : <AccessDeniedPage />,
            )
            .with({ name: "OnboardingArea" }, () =>
              canViewOnboarding ? (
                <OnboardingArea membershipEmail={membershipEmail} />
              ) : (
                <AccessDeniedPage />
              ),
            )
            .with({ name: "HoldersArea" }, { name: "HoldersVerificationArea" }, () =>
              canViewAccountHolder ? (
                <AccountHoldersArea membershipEmail={membershipEmail} />
              ) : (
                <AccessDeniedPage />
              ),
            )
            .with({ name: "AccountsArea" }, () =>
              canViewAccount ? <AccountsArea /> : <AccessDeniedPage />,
            )
            .with({ name: "MerchantArea" }, () => <MerchantArea />)
            .with({ name: "AccountMembershipsArea" }, () =>
              canViewAccountMembership ? <AccountMembershipsArea /> : <AccessDeniedPage />,
            )
            .with({ name: "CardArea" }, () => (canViewCard ? <CardArea /> : <AccessDeniedPage />))
            .with({ name: "CapitalDepositArea" }, () =>
              canViewCapitalDeposit ? <CapitalDepositArea /> : <AccessDeniedPage />,
            )
            .with({ name: "TransactionsArea" }, { name: "TransactionsVerificationArea" }, () =>
              canViewTransaction ? <TransactionsArea /> : <AccessDeniedPage />,
            )
            .otherwise(() => (
              <NotFoundPage />
            ))}
        </ScrollView>
      </View>
    </ScrollView>
  );
};
